<template>
    <article class="tile is-child notification is-danger box-padding shadow" :key="'mp'+tick" style="background-image: url('/images/helper-bg.png');    background-position: center; background-repeat: no-repeat; background-size: cover;">
      <div class="level">
        <div class="level-left">
          <p class="title text-shadow"><b-icon icon="factory"/> {{ $store.getters['machineInventar/showInventarMachines'].length }} Helper  <span v-if="outageMachines.length > 0" @click="() => { outageFilter = !outageFilter; itemFilter=null; tick++; }" class="has-text-danger" style="margin-left: 2rem;"><b-icon pack="fas" icon="exclamation-triangle"/> {{ outageMachines.length }}</span> <span v-if="outageFilter" @click="() => { outageFilter = !outageFilter; itemFilter=null; tick++; }"><b-icon pack="fas" icon="undo"/></span></p>
        </div>
        <div class="level-right">
          <span v-if="itemFilter !== null" @click="() => { itemFilter = null; tick++; }" style="margin-right: 1rem;"><b-icon pack="fas" icon="undo"/></span>
          <object-dropdown v-model="itemFilter" @change="tick++" :objects="$store.getters['object/showObjects'].filter(objA =>$store.getters['machineInventar/showInventarMachines'].some(objB => objA.id === objB.target))"/>
        </div>
      </div>
      <div style="max-width: 824px" class="content">
            <b-carousel-list
                    v-model="values"
                    :data="filter()"
                    :arrow="true"
                    :arrow-hover="true"
                    :refresh="true"
                    :has-drag="true"
                    :repeat="false"
                    :items-to-show="maxItems"
                    :items-to-list="1">
                <template slot="item" slot-scope="list">
                    <div class="card shadow" style="width: 270px;">
                      <div class="card-image p-10" :style="'background-image: url('+getMachine(list.list.object).picture+');    background-position: center; background-repeat: no-repeat; background-size: cover;'">

                        <div v-if="typeof list.list.outage !== 'undefined'  && list.list.outage" style="height:272px; padding-top: 1rem; padding-bottom: 2rem; ">
                          <div class="content bg-black-opacity has-text-white shadow p-10" style="padding-top: 1rem;">
                            <p class="title has-text-danger"><b-icon pack="fas" icon="exclamation-triangle" size="is-medium"/></p>
                            <p class="has-text-white"><b-icon pack="fas" icon="tasks" size="is-large"/> </p>
                            <required-objects  ref="outageFee" type="object" :requirements="getMachine(list.list.object).outageFee"/>
                          </div>
                          <figure class="image is-64x64" style="margin-left: 8rem;"  v-if="getObject(list.list.target).picture  !== ''" >
                            <transition><img :src="getObject(list.list.target).picture" :class="glowColor(getObject(list.list.target))"></transition>
                          </figure>
                          <span v-else class="has-text-weight-bold is-size-4">{{getObjectName(list.list.target)}}</span>
                        </div>
                        <div v-else style="padding-left: 6rem; padding-top: 1rem; padding-bottom: 2rem; ">
                          <figure class="image is-128x128" >
                            <img v-if="list.list.timer !== false" src="/images/machines/smoke.gif">
                          </figure>
                          <figure class="image is-64x64"  v-if="getObject(list.list.target).picture  !== ''" style="padding-top: 60px;">
                            <transition><img :src="getObject(list.list.target).picture" :class="glowColor(getObject(list.list.target))"></transition>
                          </figure>
                          <span v-else class="has-text-weight-bold is-size-4">{{getObjectName(list.list.target)}}</span>
                        </div>
                      </div>



                        <footer class="card-footer  " :class="cardColor(list.list)">
                            <div class="card-footer-item">
                              <span v-if="list.list.timer !== false">
                                <p class="subtitle"><b-icon icon="clock" size="is-medium"/><br> {{list.list.count*getMachine(list.list.object).itemsPerSecond}} / s </p>
                              </span>
                              <span v-else>
                                <b-icon icon="clock" size="is-small"/>  0 / s
                              </span>
                            </div>
                            <div class="card-footer-item">
                              <div class="level">
                                <div class="level-item has-text-centered">
                                  <div>
                                    <p style="margin-bottom: 3px;"><b-icon pack="fas" icon="users"/> {{list.list.count}}</p>
                                    <p class="is-size-7"><b-icon pack="fas" icon="exclamation-triangle" size="is-small"/> {{ calcOutage(getMachine(list.list.object).outagePossibility,list.list.count) }}%</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="card-footer-item">
                              <span v-if="typeof list.list.outage !== 'undefined'  && list.list.outage"><a href="#" @click.prevent="restartMachine(list.list)"><b-icon icon="wrench" size="is-medium"/></a></span>
                                <span v-else-if="list.list.timer !== false">
                                    <a  href="#" @click.prevent="stopMachine(list.list)"><b-icon icon="pause" size="is-medium"/></a>
                                </span>
                                <span v-else><a href="#" @click.prevent="startMachine(list.list)"><b-icon icon="play" size="is-medium"/></a></span>
                            </div>
                        </footer>
                    </div>
                </template>
            </b-carousel-list>
        </div>
    </article>
</template>
<script>
    import BCarouselList from "buefy/src/components/carousel/CarouselList";
    import BIcon from "buefy/src/components/icon/Icon";
    import RequiredObjects from "@/components/RequiredObjects.vue";
    import ObjectDropdown from "@/components/ObjectDropdown.vue";

    export default {
        name:'machinePark',
        components:{ObjectDropdown, RequiredObjects, BIcon, BCarouselList},
        data() {
          return {
            values:0,
            outageFilter: false,
            itemFilter: null,
            tick: 1
          }
        },
        computed: {
          maxItems() {
              return this.getMaxItems()
          },
          outageMachines() {
            return this.$store.getters['machineInventar/showInventarMachines'].filter(m => m.outage)
          },
          width() {
            return window.innerWidth
          }
      },
        methods: {
          filter() {
            let machines = this.$store.getters['machineInventar/showInventarMachines']
            if (this.outageFilter) machines = machines.filter(m => m.outage)
            if (this.itemFilter !== null ) machines = machines.filter(m => m.target === this.itemFilter.id)
            return machines
          },
            getMaxItems() {
              if (window.innerWidth < 768) {
                return 1
              } else if (window.innerWidth < 960) {
                return 2
              } else {
                return 3
              }
            },
            getMachineName(id) {
                return this.getMachine(id).name
            },
            getMachine(id) {
                return this.$store.getters['machine/showMachines'].find(obj => obj.id === id)
            },
            getObjectName(id) {
                return this.getObject(id).name
            },
            getObject(id) {
               return this.$store.getters['object/showObjects'].find(obj => obj.id === id)
            },
          getObjectAmount(id) {
              return this.$store.getters['inventar/getObjectAmount'](id)
          },
          requirementsMet(object) {
            return object.requirements.filter(obj => this.getObjectAmount(obj.object) < obj.amount ).length === 0
          },
          glowColor (object) {
            if (this.requirementsMet(object)) {
              return 'is-rounded glow-success'
            } else {
              return 'is-rounded glow-danger'
            }
          },
          cardColor (obj) {
            if (obj.timer === false && obj.outage !== 'undefined'  && obj.outage)
            {
              return 'has-background-danger has-text-white'
            } else if (obj.timer !== false) {
              return 'has-background-success'
            } else {
              return 'has-background-warning'
            }
          },
            restartMachine(machine) {
              this.$store.dispatch('machineInventar/fixMachine', machine);
            }
        }
    }
</script>