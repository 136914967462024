import { machineInventarService } from './services';
import { ToastProgrammatic as Toast } from 'buefy'
import startMachine from "@/mixins";



export const machineInventar = {
    namespaced: true,
    state: {
        all: []

    },
    actions: {
      clearMachinesWithZeroCount({state}){
        state.all = state.all.filter( maschine => maschine.count > 0 )
      },
        addMachine({ commit,state,rootGetters, dispatch},data) {

            let allInventarMachines = state.all;
            let allInventar = rootGetters['inventar/showInventarItems'];

            // suche die erstellende Machine im Inventar, falls vorhanden
            let item = allInventarMachines.find(sobj => sobj.object === data.objid && sobj.target === data.target);
            // finde das zugehörige Machinen Object
            let object = rootGetters['machine/showMachines'].find(obj => obj.id === data.objid);

            if (machineInventarService.checkAvailabilityObjects(object,allInventar,data.count) && machineInventarService.checkAvailabilityMachines(object,allInventarMachines,data.count,data.target)) {

                allInventarMachines = machineInventarService.reduceMachineCount(object,allInventarMachines,data.count,data.target);
                let items = machineInventarService.reduceObjectCount(object,allInventar,data.count);
                allInventar = items[0];
                let updatedItems = items[1];
                updatedItems.forEach(uItem =>dispatch('inventar/updateItem',uItem,{root: true}));
                if(item) {
                    clearInterval(item.timer);
                    item.count = item.count+data.count;
                    startMachine(item)
                  /*
                    item.timer = setInterval(() => {
                        dispatch('inventar/addItem',{objid: data.target, count: item.count*object.itemsPerSecond},{root: true})
                    },1000) */
                } else {
                    //let obj = {'object': data.objid, 'count': data.count, 'target': data.target, timer: setInterval(() => {dispatch('inventar/addItem', {objid:data.target, count:data.count * object.itemsPerSecond}, {root:true})},1000)};
                  let obj = {'object': data.objid, 'count': data.count, 'target': data.target, timer: false};
                  startMachine(obj)
                    allInventarMachines.push(obj)
                }
            } else {
                Toast.open({
                    message: 'Not enough required items or machines!',
                    type: 'is-danger'
                })
            }



            commit('set',allInventarMachines);
            Toast.open({
                message: 'Whooop! Happy working little helper!',
                type: 'is-success'
            })
        },
      fixMachine({ rootGetters }, machine) {
        let allInventar = rootGetters['inventar/showInventarItems'];

        // finde das zugehörige Machinen Object
        let machineObject = rootGetters['machine/showMachines'].find(obj => obj.id === machine.object);

        if (machineInventarService.checkAvailabilityOutageFee(machineObject,allInventar)) {
            machineInventarService.reduceObjectCountForOutage(machineObject,allInventar)
            startMachine(machine)
        } else {
          Toast.open({
            message: 'Not enough required items!',
            type: 'is-danger'
          })
        }

        Toast.open({
          message: 'Whooop! Happy working little helper!',
          type: 'is-success'
        })
      },
        sync ({state}) {
            localStorage.setItem('machineInventar',JSON.stringify(state.all));
        },
       //refresh ({commit,dispatch,rootGetters}) {
        refresh ({commit}) {
            if(localStorage.getItem('machineInventar') !== null) {
                let items = JSON.parse(localStorage.getItem('machineInventar'));
                items.forEach(item => {
                    // let object = rootGetters['machine/showMachines'].find(obj => obj.id === item.object);
                    // item.timer = setInterval(() => {dispatch('inventar/addItem',{objid: item.target, count: item.count*object.itemsPerSecond},{root: true})},1000)
                  item.timer = false
                });
              Toast.open({
                message: 'Heja! Welcome back! Your helpers are resting. Just wake them up!',
                type: 'is-success'
              })
                commit('set',items);
            }
        },
        clearAllIntervals({state}) {
            state.all.forEach(machine =>{
                clearInterval(machine.timer)
            })
        }
    },
    mutations: {
        set(state, items) {
            state.all = items
        },
    },
    getters: {
        showInventarMachines(state) {
            return state.all;
        },
        getObjectAmount: (state) => (id,targetid) => {
            let obj = state.all.find(item => item.object === id && item.target === targetid);
            return (obj) ? obj.count : 0;
        }
    }
};