import {ToastProgrammatic as Toast} from 'buefy';
import { store } from "@/store";

const messages = [
    'An unforeseen event has occurred with a helper, and there\'s a possibility of injury!',
  "A surprising turn of events involving a helper has left them potentially injured!",
  "An unexpected incident has occurred with a helper, raising concerns about their well-being.",
  "Something out of the ordinary has happened with a helper, and there's a chance of injury!",
  "A twist of fate with a helper has unfolded, bringing uncertainty and possible injury.",
  "A helper has encountered an unexpected situation, leading to concerns about their safety and well-being."
]

export default function  startMachine(obj) {
  obj.outage = false
  obj.timer = setInterval(() => {
    let outageP = getMachine(obj.object).outagePossibility *(1 + obj.count * 0.2);
    let rand1 = Math.floor(Math.random() * outageP)
    let rand2 = Math.floor(Math.random() * outageP)
    let rand3 = Math.floor(Math.random() * outageP)
    if (rand1 === rand2 && rand2 === rand3) {
      clearInterval(obj.timer);
      obj.timer = false
      obj.outage = true
      Toast.open({
        message: messages[Math.floor(Math.random() * messages.length)],
        type:'is-danger'
      })
    } else {

      store.dispatch('inventar/addItem',{objid: obj.target, count: obj.count*getMachine(obj.object).itemsPerSecond},{root: true})
    }
  },1000)
}


function getMachine(id) {
  return store.getters['machine/showMachines'].find(obj => obj.id === id)
}