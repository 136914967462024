<template>
    <span class="tweened-number">{{ animatedNumber }}</span>
</template>


<script>

    import gsap from 'gsap'

    export default {
        name:"AnimatedCounter",
        props: {
            value: {
                type: Number,
                required: true
            }
        },
        data() {
            return {
                number: 0,
                tweenedNumber: 0
            }
        },
        computed: {
            animatedNumber: function() {
                return this.tweenedNumber.toFixed(0);
            }
        },
        watch: {
            value: function(newValue) {
                gsap.to(this.$data, { duration: 0.5, tweenedNumber: newValue });
            }
        }
    }

</script>

<style scoped>

</style>