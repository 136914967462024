<template>
    <article class="tile is-child notification shadow" :key="tick + 'wb'" :style="'background-image: url('+background+');    background-position: center; background-repeat: no-repeat; background-size: cover;'"
             :class="`is-${type}`">
        <div class="level">
          <div class="level-left">
            <h1 v-if="currentMachine().name === 'select'"  class="subtitle text-shadow"><b-icon icon="account-hard-hat"/> educate & engineer</h1>
            <h1 v-else class="subtitle text-shadow"><b-icon icon="factory"/> <animated-counter :value="$store.getters['machineInventar/getObjectAmount'](currentMachine().id,currentObject().id)"></animated-counter></h1>
          </div>
          <div class="level-right">
            <object-dropdown ref="machineref" :objects="$store.getters['machine/showMachines'].filter(obj => obj.level <= $store.getters['level/showCurrentLevel'].id)" @change="tick++"/>
            <object-dropdown ref="objectref"  v-show="currentMachine().name !== 'select'" :objects="(currentMachine().type === 'miner' ) ? $store.getters['object/getBaseObjects'].filter(obj => obj.level <= $store.getters['level/showCurrentLevel'].id) : $store.getters['object/getConstructedObjects'].filter(obj => obj.level <= $store.getters['level/showCurrentLevel'].id)"/>
          </div>
        </div>
      <div class="content">
        <div v-show="currentMachine().name !== 'select' && currentObject().name === 'select'" class="box bg-white-opacity">
          <h4 class="has-text-grey-dark">What do you want me to do?</h4>
        </div>

       <b-taglist v-if="currentObject().name !== 'select'" class="is-centered" style="margin-top: 2rem;" attached>
          <b-tag  class="bg-white-opacity">
            <b-icon icon="clock"/>
          </b-tag>
          <b-tag class="bg-white-opacity">
            <figure class="image is-48x48"  v-if="currentObject().picture  !== ''" >
              <img :src="currentObject().picture" class="is-rounded">
            </figure>
          </b-tag>
          <b-tag  class="bg-white-opacity">
            <span class="is-bold">{{currentMachine().itemsPerSecond}}</span>
          </b-tag>
        </b-taglist>
        <b-taglist v-if="currentObject().name !== 'select'" class="is-centered" style="margin-top: 2rem;" attached>
          <b-tag class="bg-white-opacity">
            <b-icon pack="fas" icon="exclamation-triangle" />
          </b-tag>
          <b-tag class="bg-white-opacity" >
            {{ calcOutage(currentMachine().outagePossibility,1) }}%
          </b-tag>
        </b-taglist>
        </div>

      <b-button :disabled="currentMachine().name === 'select' || currentObject().name === 'select'" style="margin-top: 240px;"  :type="`is-${buttontype}`" size="is-large" expanded @click="$store.dispatch('machineInventar/addMachine',{objid: currentMachine().id, count: count, target:currentObject().id})"><b-icon icon="account-hard-hat" size="is-large"/></b-button>
      <div v-if="currentMachine().name !== 'select'" class="content bg-black-opacity has-text-white p-10">
        <p class="has-text-white"><b-icon pack="fas" icon="tasks" size="is-large"/> Helpers</p>
        <required-objects type="machine" :target="getCurrentObject()" :requirements="currentMachine().requirements.machines"/>
        <p class="has-text-white"><b-icon pack="fas" icon="tasks" size="is-large"/> Items</p>
        <required-objects type="object" :requirements="currentMachine().requirements.objects"/>


        <div style="opacity: 0.5;">
          <p class="has-text-white"><b-icon pack="fas" icon="tasks" size="is-large"/> Repairs</p>
          <required-objects type="object" :requirements="currentMachine().outageFee"/>
        </div>

      </div>
      <!--
        <figure class="image is-4by3">
            <img src="https://bulma.io/images/placeholders/640x480.png">
        </figure> --->
    </article>
</template>
<script>
    import ObjectDropdown from "./ObjectDropdown";
    import BIcon from "buefy/src/components/icon/Icon";
    import RequiredObjects from "./RequiredObjects";
    import AnimatedCounter from "./AnimatedCounter";
    export default {
        name:'Workbench',
        components:{AnimatedCounter, RequiredObjects, BIcon, ObjectDropdown},
        props:{
            machine:{},
            title: {},
            type: {},
            buttontype: {}
        },
        data() {
            return {
                currentType: 'miner',
                showOutageFee: false,
                count: 1,
                mount:false,
                tick: 1
            }
        },
        mounted() {
            this.mount = true;

        },
      computed: {
          background() {
            return this.currentMachine().name === 'select' ? '/images/machines/default.png' : this.currentMachine().picture;
          },
        currentM() {
            return this.currentMachine()
        }
      },
        methods: {
            getObjectName(id) {
                return this.$store.getters['object/showObjects'].find(obj => obj.id === id).name
            },
            getMachineName(id) {
                return this.$store.getters['machine/showMachines'].find(obj => obj.id === id).name
            },
            currentObject() {
                return (this.mount) ? this.getCurrentObject() : {name: 'select', description: 'loading ....', requirements: {machines: [], objects: []}};
            },
            getCurrentObject() {
                return this.$refs.objectref.current
            },
            currentMachine() {
                return (this.mount) ? this.getCurrentMachine() : {name: 'select', description: 'loading ....', requirements: {machines: [], objects: []}};
            },
            getCurrentMachine() {
                return this.$refs.machineref.current
            },
            setCurrent() {
                this.$refs.machineref.current = {name: 'select', description: 'material to mine', requirements: []};
                this.$refs.objectref.current = {name: 'select', description: 'material to mine', requirements: []};
            }
        }

    }
</script>

<style>
    .has-addons {
        width: 100%;
    }
</style>