import Vue from 'vue';
import Vuex from 'vuex';

import { level } from './level';
import { object } from './object';
import { inventar } from './inventar';
import { machine } from './machine';
import { machineInventar } from './machineInventar';

Vue.use(Vuex);

export const store = new Vuex.Store({
    modules: {
        level,
        object,
        inventar,
        machine,
        machineInventar
    }
});

