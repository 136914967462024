<template>
    <article class="tile is-child notification box-padding shadow" style="background-image: url('/images/research.png');    background-position: center; background-repeat: no-repeat; background-size: cover;"
                :class="`is-${type}`">
        <div class="level">
            <div class="level-left">
                <h1 class="title text-shadow"><b-icon icon="feature-search"></b-icon>{{title}}</h1>
            </div>
            <div class="level-right">
            </div>
        </div>
        <div class="content">
            <div class="box bg-white-opacity">
                <div class="content">
                    <h1 class="title">{{current.name}}</h1>
                    <h2 class="subtitle">current</h2>
                </div>
            </div>
            <div class="box bg-white-opacity">
                <div class="content">
                    <h5>Next {{next.name}} ...</h5>
                    <h6>... requires</h6>
                    <required-objects type="object" :requirements="next.requirements"/>

                    <h6>... unlocks items</h6>
                    <b-field grouped group-multiline>
                        <div v-for="(o,i) in unlocks()" :key="i" class="control">
                            <b-tag type="is-success">
                              <figure class="image is-32x32" style="margin: 0;" v-if="o.picture !== ''">
                              <b-tooltip :label="o.name">
                                <img :src="o.picture" class="is-rounded">
                              </b-tooltip>
                              </figure>
                            </b-tag>
                        </div>
                    </b-field>
                  <h6>... unlocks helpers</h6>
                  <b-field grouped group-multiline>
                    <div v-for="(o,i) in getUnlockHelpers()" :key="i" class="control">
                      <b-tag type="is-success">
                        <figure class="image is-32x32" style="margin: 0;" v-if="o.picture !== ''">
                          <b-tooltip :label="o.name">
                            <img :src="o.picture" class="is-rounded">
                          </b-tooltip>
                        </figure>
                      </b-tag>
                    </div>
                  </b-field>
                </div>
            </div>
        </div>
        <b-button :type="`is-${buttontype}`" expanded @click="research" size="is-large"><b-icon icon="feature-search" size="is-large"></b-icon></b-button>
    </article>
</template>
<script>
    import BButton from "buefy/src/components/button/Button";
    import BTag from "buefy/src/components/tag/Tag";
    import BField from "buefy/src/components/field/Field";
    import RequiredObjects from "./RequiredObjects";
    import BIcon from "buefy/src/components/icon/Icon";
    export default {
        name:'research',
        components:{BIcon, RequiredObjects, BField, BTag, BButton},
        props:{
            objects:{},
            title: {},
            type: {},
            buttontype: {}
        },
        data() {
            return {
                count: 1,
                mount: false
            }
        },
        mounted() {
          this.mount = true;
        },
        computed: {
            current() {
                return (this.mount) ? this.getCurrent() : { id: 0, name: 'select', description: 'loading ....'};
            },
            next() {
                return (this.mount) ? this.getNext() : {id: 1, name: 'select', description: 'loading ....'};
            },
        },
        methods: {

            getCurrent() {
                return this.$store.getters['level/showCurrentLevel']
            },
            getNext() {
                return this.$store.getters['level/showNextLevel']
            },
            unlocks() {
                return (this.mount) ? this.getUnlockObjects() : {name: 'select', description: 'loading ....'};
            },
            getUnlockObjects() {
                return this.$store.getters['object/getObjectsByLevel'](this.getNext().id)
            },
          getUnlockHelpers() {
            return this.$store.getters['machine/getMaschineByLevel'](this.getNext().id)
          },
          research() {
            this.$store.dispatch('level/research');

            let gameID = localStorage.getItem('gameID');

            if (gameID !== null) {
              // Update existing game
              this.updateGame(gameID);
            } else {
              // Create new game
              this.createNewGame();
            }
          },
          updateGame(gameID) {
            const currentLevelId = this.$store.getters['level/showCurrentLevel'].id;

            // Update key value for the existing game
            fetch(`https://keyvalue.immanuel.co/api/KeyVal/UpdateValue/${process.env.VUE_APP_APPID}/${gameID}/${currentLevelId}`, { method: 'POST' });
          },
          createNewGame() {
            // Create a new game and update key value
            fetch(`https://keyvalue.immanuel.co/api/KeyVal/GetValue/${process.env.VUE_APP_APPID}/counter`)
                .then(response => {
                  if (!response.ok) {
                    console.log('Something happened with the key-value store');
                    throw new Error('Key-value store error');
                  }
                  return response.text();
                })
                .then(data => {
                  const cleanedData = data.replace(/"/g, '');
                  let newGameID = parseInt(cleanedData,10) + 1;
                  localStorage.setItem('gameID', newGameID);

                  // Update key value for the new game
                  fetch(`https://keyvalue.immanuel.co/api/KeyVal/UpdateValue/${process.env.VUE_APP_APPID}/counter/${newGameID}`, { method: 'POST' });
                  fetch(`https://keyvalue.immanuel.co/api/KeyVal/UpdateValue/${process.env.VUE_APP_APPID}/${newGameID}/${this.$store.getters['level/showCurrentLevel'].id}`, { method: 'POST' });
                })
                .catch(error => {
                  console.error('Error:', error.message);
                }).catch(() => {});
          }
        }

    }
</script>
<style>
    ul.requires li {
        display:inline;
    }
</style>