<template>
  <div id="app">
    <section class="hero bg-blue-opacity">
      <div class="hero-body" style="padding-top: 0px; padding-bottom: 0px">
        <div class="container is-center">
          <div class="level">
            <div class="level-left ">
              <figure class="image " style="width: 233px; margin-left: auto; margin-right: auto">
                <img src="/images/logo-raw.png"  class="media-left" alt="Craftopia Chronicles - Embark on the Crafting Odyssey of a Lifetime! - ">
              </figure>
            </div>
            <div class="level-right">
              <h2 class="subtitle">
                <a href="#" @click.prevent="restart">restart</a>
              </h2>
            </div>
          </div>


        </div>
      </div>
    </section>
    <section class="section">
    <div class="container  is-widescreen">
      <!---<h2 class="subtitle">
        collect raw materials and produce items and machines on the workbench
      </h2> --->
      <MainGameScreen/>
      <p># {{ currentGameID }} - {{ totalgames }}</p>
    </div>
    </section>
    <!---<Story></Story>--->
    <div class="is-hidden-tablet">
      <b-navbar :mobile-burger="false" fixed-bottom shadow centered type="is-warning">
        <template slot="brand">
          <b-navbar-item href="#source">
            <div class="block">
              <b-icon icon="hammer" size="is-large"></b-icon><br>
              <span class="is-bold is-size-7">source</span>
            </div>
          </b-navbar-item>
          <b-navbar-item href="#process" v-if="$store.getters['level/showCurrentLevel'].id >1">
            <div class="block">
              <b-icon icon="hammer-screwdriver" size="is-large"></b-icon><br>
              <span class="is-bold is-size-7">process</span>
            </div>
          </b-navbar-item>
          <b-navbar-item href="#workbench" v-if="$store.getters['level/showCurrentLevel'].id >2">
            <div class="block">
              <b-icon icon="account-hard-hat" size="is-large"></b-icon><br>
              <span class="is-bold is-size-7">edu&eng</span>
            </div>
          </b-navbar-item>
          <b-navbar-item href="#research">
            <div class="block">
              <b-icon icon="feature-search" size="is-large"></b-icon><br>
              <span class="is-bold is-size-7">research</span>
            </div>
          </b-navbar-item>
          <b-navbar-item href="#machines" v-if="$store.getters['level/showCurrentLevel'].id >2">
            <div class="block">
              <b-icon icon="factory" size="is-large"></b-icon><br>
              <span class="is-bold is-size-7">machines</span>
            </div>
          </b-navbar-item>
          <b-navbar-item href="#inventory">
            <div class="block">
              <b-icon icon="warehouse" size="is-large"></b-icon><br>
              <span class="is-bold is-size-7">inventory</span>
            </div>
          </b-navbar-item>
        </template>
      </b-navbar>
    </div>
  </div>
</template>

<script>


  import MainGameScreen from "./components/MainGameScreen";
  import BNavbar from "buefy/src/components/navbar/Navbar";
  import BNavbarItem from "buefy/src/components/navbar/NavbarItem";
  import BIcon from "buefy/src/components/icon/Icon";


  export default {
  name: 'App',
  components:{BIcon, BNavbarItem, BNavbar, MainGameScreen},
    data() {
    return {
      syncTimer: null,
      cleanupTimer: null,
      totalgames: 0
    }
    },
    created() {
      this.$store.dispatch('object/init');
      this.$store.dispatch('level/init');
      this.$store.dispatch('machine/init');
      this.$store.dispatch('machineInventar/refresh');
      this.$store.dispatch('inventar/refresh');
      this.$store.dispatch('level/refresh');

      this.getLatestGameID()

      this.syncTimer = setInterval(() => {
        this.$store.dispatch('machineInventar/sync');
        this.$store.dispatch('inventar/sync');
        this.$store.dispatch('level/sync');},
      5000)

      this.cleanupTimer = setInterval(() => {
            this.$store.dispatch('machineInventar/clearMachinesWithZeroCount');
            },
          5000)
    },
    computed: {
      currentGameID() {
        return localStorage.getItem('gameID')
      }
    },
    methods: {
      restart() {
        this.$store.dispatch('machineInventar/clearAllIntervals');
        this.$store.dispatch('level/init');
        this.$store.commit('inventar/set',[]);
        this.$store.commit('machineInventar/set',[]);
        localStorage.removeItem('gameID')
      },
      getLatestGameID() {
        fetch(`https://keyvalue.immanuel.co/api/KeyVal/GetValue/${process.env.VUE_APP_APPID}/counter`)
            .then(response => {
              if (!response.ok) {
                throw new Error('Key-value store error');
              }
              return response.text();
            })
            .then(data => {
              const cleanedData = data.replace(/"/g, '');
              this.totalgames = parseInt(cleanedData,10)
              })
            .catch(error => {
              console.error('Error:', error.message);
            })

      }
    }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 0px;
}

button {
  touch-action: manipulation;
}


.navbar-item {
  padding-left: 7px !important;
  padding-right: 7px !important;
  border-right-style: dashed;
  border-right-width: 1px;
  border-right-color: #333333;
}

  .navbar-brand {
    justify-content: center;
  }

  .bg-black-opacity {
    background: rgba(0,0,0,0.7) !important;
  }

.bg-white-opacity {
  background: rgb(255, 255, 255, 0.7) !important;
}

.bg-blue-opacity {
  background: rgba(20, 51, 86, 0.59) !important;
}

  .p-10 {
    padding: 10px
  }

.p-1 {
  padding: 1rem;
}

.p-2 {
  padding: 2rem;
}

.box-padding {
  padding: 1.25rem 1.5rem 1.25rem 1.5rem !important;
}

.glow {
  -moz-box-shadow: 0 0 5px #fff;
  -webkit-box-shadow: 0 0 5px #fff;
  box-shadow: 0 0 5px 5px #fff;
}



.shadow {
  -moz-box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.7);
  -webkit-box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.7);
  box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.7);
}

.text-shadow {
  text-shadow: 1px 1px 10px black;
}

.parallax {
  /* The image used */
  background-image: url("/images/bg.png");
  background-position: center; background-repeat: no-repeat; background-size: cover;
}
</style>
