<template>
    <b-field grouped group-multiline>
        <div v-for="(r,i) in requirements" :key="'ro'+type+i" class="control">
            <b-taglist attached>
                <b-tag type="is-dark">
                  <figure class="image is-32x32" style="margin: 0;" v-if="getPicture(r.object) !== ''">
                    <b-tooltip :label="getName(r.object) + ' :' + getId(r.object)">
                      <img :src="getPicture(r.object)" class="is-rounded">
                    </b-tooltip>
                  </figure>
                  <span v-else>{{getName(r.object)}}</span>
                </b-tag>
                <b-tag :type="(r.amount <= getObjectAmount(r.object)) ? 'is-success' : 'is-danger'">{{r.amount}} ( <b-icon size="is-small"  icon="warehouse"/> <animated-counter :value="getObjectAmount(r.object)"/>)</b-tag>
            </b-taglist>
        </div>
    </b-field>
</template>
<script>
    import BField from "buefy/src/components/field/Field"
    import BTag from "buefy/src/components/tag/Tag"
    import BTaglist from "buefy/src/components/tag/Taglist"
    import AnimatedCounter from "./AnimatedCounter";

    export default {
        name:'required-objects',
        components:{AnimatedCounter, BField, BTag, BTaglist},
        props:{
            requirements:{},
            type: {},
            target: {}
        },
        methods: {
          requirementsMet() {
            return this.requirements.filter(obj => this.getObjectAmount(obj.object) < obj.amount ).length === 0
          },
            getName(id) {
                return this.getObject(id).name
            },
          getPicture(id) {
            return this.getObject(id).picture
          },
          getId(id) {
            return this.getObject(id).id
          },
            getObjectAmount(id) {
                if (this.type === 'machine') {
                    return this.$store.getters[this.getInventarStore()](id,this.target.id)
                } else {
                    return this.$store.getters[this.getInventarStore()](id)
                }
            },
            getObject(id) {
                return this.$store.getters[this.getStore()].find(obj => obj.id === id);
            },
            getStore() {
                return (this.type === 'object')? 'object/showObjects' : 'machine/showMachines'
            },
            getInventarStore() {
                return (this.type === 'object')? 'inventar/getObjectAmount' : 'machineInventar/getObjectAmount'
            }
        }
    }
</script>
<style>
    ul.requires li {
        display: inline;
    }
</style>