export const machineInventarService = {
    checkAvailabilityObjects,
    reduceObjectCount,
    checkAvailabilityMachines,
    checkAvailabilityOutageFee,
    reduceObjectCountForOutage,
    reduceMachineCount
};

function checkAvailabilityObjects(objToBuild, inventarItems, count) {

    if (objToBuild.requirements.objects.length > 0) {
        let available = true;
        objToBuild.requirements.objects.forEach(item => {
            // check single Item in store inventarMachines
            let avai = inventarItems.find(it => it.object === item.object);
            if(avai) {
                available = (available) ? avai.count >= item.amount * count : available;
            } else {
                available = false;
            }
        });
        return available;
    } else {
        return true;
    }
}

function checkAvailabilityOutageFee(objToBuild, inventarItems) {

  if (objToBuild.outageFee.length > 0) {
    let available = true;
    objToBuild.outageFee.forEach(item => {
      // check single Item in store inventarMachines
      let avai = inventarItems.find(it => it.object === item.object);
      if(avai) {
        available = (available) ? avai.count >= item.amount : available;
      } else {
        available = false;
      }
    });
    return available;
  } else {
    return true;
  }
}

function reduceObjectCount(objToBuild, inventarItems,count) {
    let newItems = [];
    objToBuild.requirements.objects.forEach(item => {
        // check single Item in store inventarItems
        let objToReduce = inventarItems.find(it => it.object === item.object);
        objToReduce.count = objToReduce.count - item.amount*count;
        newItems.push(objToReduce);
    });

    return [inventarItems,newItems];
}

function reduceObjectCountForOutage(objToBuild, inventarItems) {
  objToBuild.outageFee.forEach(item => {
    // check single Item in store inventarItems
    let objToReduce = inventarItems.find(it => it.object === item.object);
    objToReduce.count = objToReduce.count - item.amount;
  });
  return [inventarItems];
}


function checkAvailabilityMachines(objToBuild, inventarMachines, count,target) {

    if (objToBuild.requirements.machines.length > 0) {
        let available = true;
        objToBuild.requirements.machines.forEach(item => {
            // check single Item in store inventarMachines
            let inventarMachine = inventarMachines.find(it => it.object === item.object && it.target === target);
            if(inventarMachine) {
                available = (available) ? item.amount*count <= inventarMachine.count : available;
            } else {
                available = false;
            }
        });
        return available;
    } else {
        return true;
    }
}

function reduceMachineCount(objToBuild, inventarMachines,count, target) {
    objToBuild.requirements.machines.forEach(item => {
        // check single Item in store inventarItems
        let objToReduce = inventarMachines.find(it => it.object === item.object && it.target === target);
        objToReduce.count = objToReduce.count - item.amount*count;
    });

    return inventarMachines;
}
