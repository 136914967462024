<template>
    <article class="tile is-child notification box-padding shadow" :style="'background-image: url('+background+');    background-position: center; background-repeat: no-repeat; background-size: cover;'"
                :class="`is-${type}`">
        <div class="level">
            <div class="level-left">
              <h1 v-if="current.name === 'select'" class="subtitle has-text-white text-shadow"><b-icon :icon="icon" size="is-large"/> {{title}}</h1>
              <h1 v-else class="subtitle has-text-white text-shadow"><b-icon icon="warehouse" size="is-large"/> <animated-counter :value="$store.getters['inventar/getObjectAmount'](current.id)"></animated-counter></h1>
            </div>
            <div class="level-right">
                <object-dropdown ref="objref" :objects="objects"/>
            </div>
        </div>
        <div >
          <click-animation ref="clickanimation" :image="current.picture" style="margin-top: 122px;" ></click-animation>
          <b-button :disabled="!requirementsMet() || current.name === 'select'" size="is-large" :type="`is-${buttontype}`" expanded @click="click(current,count)"><b-icon :icon="icon" size="is-large"></b-icon> </b-button>

            <div class="content bg-black-opacity has-text-white p-10" style="">
              <!---<h1 class="title is-1">{{current.name}}</h1> --->
              <p class="has-text-white"><b-icon pack="fas" icon="tasks" size="is-large"/> </p>
                <p v-if="current.requirements.length === 0">-</p>

                <required-objects ref="requirements" type="object" :requirements="current.requirements"/>
            </div>


        </div>
    </article>
</template>
<script>
    import BButton from "buefy/src/components/button/Button";
    import ObjectDropdown from "./ObjectDropdown";
    import RequiredObjects from "./RequiredObjects";
    import BIcon from "buefy/src/components/icon/Icon";
    import AnimatedCounter from "./AnimatedCounter";
    import ClickAnimation from "@/components/ClickAnimation.vue";

    export default {
        name:'clickField',
        components:{ClickAnimation, AnimatedCounter, BIcon, RequiredObjects, ObjectDropdown, BButton},
        props:{
            objects:{},
            title: {},
            type: {},
            buttontype: {},
            icon: {}
        },
        data() {
            return {
                count: 1,
                mount: false,
                animationTrigger: false
            }
        },
        mounted() {
          this.mount = true;
        },
        computed: {
            background() {
              return (this.mount && this.getCurrent().name === 'select') ? '/images/objects/default-'+this.title+'.png' : this.current.picture
            },
            current() {
                return (this.mount) ? this.getCurrent() : {name: 'select', description: 'loading ....', requirements: []};
            },
            requirementsDefined() {
                return (typeof this.getCurrent.requirements !== 'undefined')
            }
        },
        methods: {
            getName(id) {
                return this.$store.getters['object/showObjects'].find(obj => obj.id === id).name
            },
            getCurrent() {
                return this.$refs.objref.current
            },
          requirementsMet() {
            return (this.mount) ? this.$refs.requirements.requirementsMet() : false
          },
          click(current, count) {
            this.$store.dispatch('inventar/addItem',{objid: current.id, count: count});
            this.$refs.clickanimation.animateEmoji()
          }
        }

    }
</script>
<style>



</style>