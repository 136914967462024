<template>
    <b-dropdown v-model="current" aria-role="list" @change="updateValue">
        <button class="button is-text has-text-white" type="button" slot="trigger">
            <template>
              <figure class="image is-48x48"  v-if="current.picture  !== ''" >
                <img :src="current.picture" class="is-rounded glow">
              </figure>
              <b-icon v-else class="media-left" icon="select"></b-icon>
               <!-- <span class="has-text-weight-bold is-size-4">{{current.name}}</span> -->
            </template>
            <b-icon icon="menu-down"></b-icon>
        </button>

        <b-dropdown-item v-for="(obj,i) in objects" :value="obj" :key="i" aria-role="listitem">
            <div class="media">
              <figure class="image is-48x48 media-left" v-if="obj.picture  !== ''" >
                <img :src="obj.picture" class="is-rounded">
              </figure>
                <b-icon v-else class="media-left" icon="earth"></b-icon>
                <div class="media-content">
                    <h3 class="has-text-weight-bold">{{obj.name}} <b-icon class="media-left" size="is-small" :icon="(obj.baseobj) ? 'hammer' : 'hammer-screwdriver'"></b-icon></h3>
                    <small>{{obj.description}}</small>
                </div>
            </div>
        </b-dropdown-item>
    </b-dropdown>
</template>
<script>
    import BDropdown from "buefy/src/components/dropdown/Dropdown"
    import BDropdownItem from "buefy/src/components/dropdown/DropdownItem"

    export default {
        name:'object-dropdown',
        components:{BDropdown, BDropdownItem},
        props:{
            objects: {},
          value:{}
        },
        data() {
            return {
                current: {name: 'select', picture: '/images/select.png', description: 'material to mine', requirements: []}
            }
        },
      methods: {
        updateValue(event) {
          this.current = event
          this.$emit('input', this.current);
        }
      }
    }
</script>
<style>
    ul.requires li {
        display: inline;
    }

    .select-object {
      filter: grayscale(100%);
      background: rgb(255, 255, 255);
    }

</style>