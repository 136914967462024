<template>
    <article class="tile is-child notification is-primary box-padding shadow" style="background-image: url('/images/lock.png'); opacity: 0.8;   background-position: center; background-repeat: no-repeat; background-size: cover;">
        <div class="content bg-black-opacity has-text-white p-10">
            <p class="title text-shadow"><b-icon icon="lock"/> unlock</p>
            <p class="subtitle text-shadow">research to unlock</p>
        </div>
    </article>
</template>
<script>
    import BIcon from "buefy/src/components/icon/Icon";
    import {level} from "@/store/level";
    export default {
        name:'UnlockField',
        components:{BIcon},
      props: [level],
        data() {
            return {
            }
        },
        created() {

        },
        methods: {

        }
    }
</script>