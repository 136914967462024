<template>
    <div class="tile is-ancestor">
        <div class="tile is-vertical is-8">
            <div class="tile">
                <div class="tile is-parent is-vertical">
                  <clickField id="source" icon="hammer" title="source" type="warning" buttontype="primary" :objects="$store.getters['object/getBaseObjects'].filter(obj => obj.level <= $store.getters['level/showCurrentLevel'].id)"/>
                  <clickField v-if="$store.getters['level/showCurrentLevel'].id > 1" id="process" icon="hammer-screwdriver" title="process" type="primary" buttontype="warning" :objects="$store.getters['object/getConstructedObjects'].filter(obj => obj.level <= $store.getters['level/showCurrentLevel'].id)"/>
                  <UnlockField v-else></UnlockField>
                </div>
                <div class="tile is-parent">
                  <Workbench v-if="$store.getters['level/showCurrentLevel'].id > 2" id="workbench" title="construct" type="info" buttontype="warning"/>
                  <UnlockField v-else></UnlockField>
                </div>
            </div>
            <div class="tile is-parent">
              <machinePark v-if="$store.getters['level/showCurrentLevel'].id > 2" id="machines" />
              <UnlockField v-else></UnlockField>
            </div>
        </div>
        <div class="tile">
                <div class="tile is-parent is-vertical">
                    <research id="research" title="research" type="link" buttontype="primary"/>
                    <inventar id="inventory" />
                </div>
        </div>
    </div>
</template>


<script>
    import ClickField from "./ClickField";
    import Inventar from "./Inventar";
    import MachinePark from "./MachinePark";
    import Workbench from "./Workbench";
    import Research from "./Research";
    import UnlockField from "@/components/UnlockField.vue";


    export default {
        name:'MainGameScreen',
        components:{UnlockField, Research, Workbench, MachinePark, Inventar, ClickField},
        data() {
            return {
                machinePark: [],
            }
        },
        created() {

        },
        computed: {

        },
        methods: {

        }
    }
</script>
