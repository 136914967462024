<template>
  <div style="height: 48px; width: 48px; margin-left: auto; margin-right: auto;">
    <div v-if="showEmoji" class="emoji-container">
      <figure class="image is-48x48" >
        <img :src="image" class="is-rounded glow emoji">
      </figure>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    image: String
  },
  data() {
    return {
      showEmoji: false
    };
  },
  methods: {
    animateEmoji() {
      this.showEmoji = true;
      // You can use setTimeout or any other animation trigger based on your needs
      setTimeout(() => {
        this.showEmoji = false;
      }, 300); // Adjust the duration as needed
    },
  },
};
</script>

<style scoped>
.emoji-container {
  position: relative;
  animation: riseAnimation 0.3s ease-out;
}

.emoji {
  position: absolute;
  bottom: 0;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -100%);
}

@keyframes riseAnimation {
  from {
    transform: translateY(150%);
  }
  to {
    transform: translateY(0);
  }
}
</style>