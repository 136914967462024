import { inventarService } from './services';
import { ToastProgrammatic as Toast } from 'buefy'


export const level = {
    namespaced: true,
    state: {
        all: [],
        current: 0

    },
    actions: {
        init({ commit},) {

          // factor obj 0 (food) 1.8
          // factor obj 18 (gold) 1.3

          let levels = [
            { id: 1, name: "Pioneer's Beginnings", picture: '', description: 'Embark on your journey with basic resources—gather stones and wood to lay the foundation of your settlement.', requirements: [] },
            { id: 2, name: 'Apprentice Crafter', picture: '', description: 'Explore tools, huts, and basic crafting techniques.', requirements: [{ object: 0, amount: 10 }, { object: 1, amount: 10 }, { object: 2, amount: 10 }] },
            { id: 3, name: 'Resource Innovator', picture: '', description: 'Unleash innovation with wheels and refined materials.', requirements: [{ object: 5, amount: 10 }, { object: 3, amount: 10 }, { object: 4, amount: 10 },{ object: 0, amount: 20 }] },
            { id: 4, name: 'Metal Master', picture: '', description: 'Dive into the world of metals, ores, and advanced crafting.', requirements: [{ object: 6, amount: 20 }, { object: 7, amount: 20 }, { object: 8, amount: 20 },{ object: 0, amount: 50 }] },
            { id: 5, name: 'Industrial Artisan', picture: '', description: 'Craft intricate components for advanced structures.', requirements: [{ object: 9, amount: 100 }, { object: 10, amount: 100 }, { object: 0, amount: 500 }] },
            { id: 6, name: 'Mineral Maestro', picture: '', description: 'Master the art of refining copper and crafting essentials.', requirements: [{ object: 12, amount: 150 }, { object: 11, amount: 150 }, { object: 0, amount: 900 }] },
            { id: 7, name: 'Glass Alchemist', picture: '', description: 'Transform sand into transparent wonders and delve into mechanical creations.', requirements: [{ object: 13, amount: 150 }, { object: 14, amount: 150 }, { object: 0, amount: 1620 }] },
            { id: 8, name: 'Gold Tycoon', picture: '', description: 'Accumulate wealth with gold and unlock the secrets of paper production.', requirements: [{ object: 15, amount: 150 }, { object: 17, amount: 150 }, { object: 18, amount: 150 }, { object: 0, amount: 2916 }] },
            { id: 9, name: 'Knowledge Scribe', picture: '', description: 'Document wisdom in books and explore the wonders of literature.', requirements: [ { object: 19, amount: 200 }, { object: 18, amount: 200 }, { object: 0, amount: 5249 }] },
            { id: 10, name: 'Steam Pioneer', picture: '', description: 'Harness steam power with advanced machinery and propel your civilization.', requirements: [{ object: 20, amount: 200 }, { object: 18, amount: 260 }, { object: 0, amount: 9448 }] },
            { id: 11, name: 'Precision Engineer', picture: '', description: 'Craft specialized tools like drill bits for precision tasks.', requirements: [{ object: 21, amount: 200 }, { object: 18, amount: 338 }, { object: 0, amount: 17006 }] },
            { id: 12, name: 'Fossil Fuel Explorer', picture: '', description: 'Extract crude oil from deep underground, unlocking a new era.', requirements: [{ object: 22, amount: 200 }, { object: 18, amount: 439 }, { object: 0, amount: 30611 }] },
            { id: 13, name: 'Industrial Revolution', picture: '', description: 'Integrate steam power into processes for faster and more efficient fabric production.', requirements: [{ object: 23, amount: 200 }, { object: 18, amount: 742 }, { object: 0, amount: 55099 }] },
            { id: 14, name: 'Electrical Innovator', picture: '', description: 'Harness the power of electricity for advanced technological wonders.', requirements: [{ object: 24, amount: 200 }, { object: 18, amount: 964 }, { object: 0, amount: 99179 }] },
            { id: 15, name: 'Combustion Maestro', picture: '', description: 'Master the art of combustion with gasoline, unlocking new horizons.', requirements: [{ object: 25, amount: 200 }, { object: 18, amount: 1245 }, { object: 0, amount: 178523 }] },
            //  todo: next levels
            { id: 14, name: 'Exoplanetary Colonization', picture: '', description: 'Set the stage for colonizing distant planets, necessitating the construction of space habitats and terraforming devices.', requirements: [{ object: 26, amount: 20 }, { object: 27, amount: 15 }, { object: 24, amount: 10 }, { object: 25, amount: 10 }] },
            { id: 15, name: 'Quantum Computing Era', picture: '', description: 'Revolutionize computation with the introduction of quantum computers, pushing the boundaries of technological capabilities.', requirements: [{ object: 28, amount: 25 }, { object: 29, amount: 20 }, { object: 24, amount: 15 }, { object: 25, amount: 10 }] },
            { id: 16, name: 'Dimensional Engineering', picture: '', description: 'Explore the manipulation of dimensions, unlocking new realms of scientific possibilities.', requirements: [{ object: 30, amount: 20 }, { object: 31, amount: 15 }, { object: 28, amount: 10 }, { object: 29, amount: 10 }] },
            { id: 17, name: 'Time Distortion', picture: '', description: 'Challenge the fabric of time itself with experiments in time distortion technology.', requirements: [{ object: 32, amount: 25 }, { object: 33, amount: 20 }, { object: 31, amount: 15 }, { object: 28, amount: 10 }] },
            { id: 18, name: 'Ascendancy Beyond', picture: '', description: 'Ascend to a higher plane of existence, tapping into cosmic energies for unprecedented advancements.', requirements: [{ object: 34, amount: 20 }, { object: 35, amount: 15 }, { object: 32, amount: 10 }, { object: 33, amount: 10 }] },
            { id: 19, name: 'Multiversal Nexus', picture: '', description: 'Establish connections across multiverses, achieving the pinnacle of scientific achievement.', requirements: [{ object: 36, amount: 25 }, { object: 37, amount: 20 }, { object: 35, amount: 15 }, { object: 32, amount: 10 }] },
            { id: 20, name: "Creator's Domain", picture: '', description: 'Attain the status of a creator, shaping reality itself through mastery of omniversal energies.', requirements: [{ object: 38, amount: 20 }, { object: 39, amount: 15 }, { object: 35, amount: 10 }, { object: 33, amount: 10 }] },
            { id: 21, name: 'Cosmic Enlightenment', picture: '', description: 'Achieve enlightenment by tapping into the wisdom of the cosmos, transcending the limitations of mortal understanding.', requirements: [{ object: 40, amount: 25 }, { object: 41, amount: 20 }, { object: 37, amount: 15 }, { object: 34, amount: 10 }] },
            { id: 22, name: 'Absolute Mastery', picture: '', description: 'Attain absolute mastery over all aspects of existence, becoming a true cosmic entity.', requirements: [{ object: 42, amount: 20 }, { object: 43, amount: 15 }, { object: 39, amount: 10 }, { object: 37, amount: 10 }] },
            { id: 23, name: 'Eternal Nexus', picture: '', description: 'Forge an eternal nexus, connecting all realities and ensuring the continuity of cosmic balance.', requirements: [{ object: 44, amount: 25 }, { object: 45, amount: 20 }, { object: 43, amount: 15 }, { object: 39, amount: 10 }] },
            { id: 24, name: 'Cosmic Ascendancy', picture: '', description: 'Ascend to the highest echelons of cosmic existence, transcending even the concept of levels.', requirements: [{ object: 46, amount: 20 }, { object: 47, amount: 15 }, { object: 44, amount: 10 }, { object: 43, amount: 10 }] },
            { id: 25, name: 'Cosmic Architect', picture: '', description: 'Become the cosmic architect, shaping the destiny of the cosmos itself.', requirements: [] },

            // ... (repeat the pattern for the remaining levels)
          ];
            commit('setinit',levels);
        },
        research ({state,commit,rootGetters}) {
            // console.log(state.all[state.current+1]);
            if (inventarService.checkAvailability(state.all[state.current+1],rootGetters['inventar/showInventarItems'],1)) {
                //let newItems =
                    inventarService.reduceCount(state.all[state.current+1],rootGetters['inventar/showInventarItems'],1)[1];
                /*
                newItems.forEach(item => {
                    dispatch('inventar/updateItem',item)
                }); */
                commit('updateLevel');
                Toast.open({
                    type: 'is-success',
                    message: 'Great! You unlocked new items!'
                })
            } else {
                Toast.open({
                    type: 'is-danger',
                    message: 'That\'s not enough! Mine and construct and comeback again later!'
                })
            }
        },
        sync ({state}) {
            localStorage.setItem('gamelevel',state.current)
        },
        refresh ({commit}) {
            if(localStorage.getItem('gamelevel') !== null) {
                commit('refresh',parseInt(localStorage.getItem('gamelevel')));
            }
        }
    },
    mutations: {
        setinit(state, items) {
            state.all = items;
            state.current = 0;
        },
        updateLevel(state) {
            state.current++;
        },
        refresh (state, current) {
            state.current = current;
        }
    },
    getters: {
        showLevel(state) {
            return state.all;
        },
        showCurrentLevel(state) {
            return state.all[state.current];
        },
        showNextLevel(state) {
            let i = state.current+1;
            return state.all[i];
        },
        getLevelObject(state) {
            return id => { state.all.find(obj =>  obj.id === id  ) }
        }
    }
};