import Vue from 'vue'
import App from './App.vue'
import Buefy from 'buefy'
import './assets/scss/app.scss'
import { store } from './store';
import VueNumber from 'vue-number-animation';
import startMachine from "@/mixins";



Vue.use(VueNumber);

Vue.use(Buefy);

Vue.mixin({
  methods: {
    startMachine: startMachine,
    stopMachine(obj) {
      clearInterval(obj.timer);
      obj.timer = false;
    },
    getMachine(id) {
      return this.$store.getters['machine/showMachines'].find(obj => obj.id === id)
    },
    calcOutage (outagePossibility, count) {
      outagePossibility = outagePossibility * (1 + count * 0.2)
      let res = ((outagePossibility  / Math.pow(outagePossibility, 3)) * 100)
      return res.toFixed(Math.floor( -Math.log10(res) + 2))
    }
  }
});

Vue.config.productionTip = false

new Vue({
  store,
  render: h => h(App),
}).$mount('#app')
