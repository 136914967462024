export const machine = {
    namespaced: true,
    state: {
        all: [],

    },
    actions: {
        init({ commit},) {

            let machines= [
                { id: 1, type: 'miner', baseobj:true, name: 'Worker', description: 'Single worker for base materials', picture: '/images/machines/min/mini_miner.png', level: 2, itemsPerSecond: '1', outagePossibility: 30, outageFee: [{object: 0, amount: 50}], requirements: {objects: [{object: 0, amount: 65}, {object: 3, amount: 1}, {object: 4, amount: 1}], machines:[]}},
                { id: 2, type: 'constructor', baseobj:false, name: 'Constructor', description: 'Single constructor for processed objects', picture: '/images/machines/min/mini_constructor.png', level: 2, itemsPerSecond: '1', outagePossibility: 30, outageFee: [{object: 0, amount: 50}], requirements: {objects: [{object: 0, amount: 65}, {object: 3, amount: 1}, {object: 4, amount: 1}], machines:[]}},
                { id: 3, type: 'miner', baseobj:true, name: 'Worker Duo', description: 'An industrious duo tirelessly gathering essential base materials.', picture: '/images/machines/min/duo-miner.png', level: 3, itemsPerSecond: '3', outagePossibility: 60, outageFee: [{object: 0, amount: 80}], requirements: {objects: [{object: 0, amount: 120}, {object: 3, amount: 1}, {object: 4, amount: 1}], machines:[{object: 1, amount: 2}]}},
                { id: 4, type: 'constructor', baseobj:false, name: 'Constructor Duo', description: 'A dynamic duo specializing in the meticulous construction of refined objects.', picture: '/images/machines/min/duo-constructor.png', level: 3, itemsPerSecond: '3', outagePossibility: 60, outageFee: [{object: 0, amount: 80}], requirements: {objects: [{object: 0, amount: 120}, {object: 3, amount: 1}, {object: 4, amount: 1}], machines:[{object: 2, amount: 2}]}},
                { id: 5, type: 'miner', baseobj:true, name: 'Worker Duo with Horse', description: 'A diligent duo and a trusty horse tirelessly gather vital base materials.', picture: '/images/machines/min/duo-horse-miner.png', level: 4, itemsPerSecond: '8', outagePossibility: 40, outageFee: [{object: 0, amount: 120}], requirements: {objects: [{object: 0, amount: 500}, {object: 3, amount: 20}, {object: 4, amount: 20}], machines:[{object: 3, amount: 1}]}},
                { id: 6, type: 'constructor', baseobj:false,  name: 'Constructor Duo with Horse', description: 'A dynamic duo specializing in the meticulous construction of refined objects.', picture: '/images/machines/min/duo-horse-constructor.png', level: 5, itemsPerSecond: '8', outagePossibility: 40, outageFee: [{object: 0, amount: 120}], requirements: {objects: [{object: 0, amount: 600}, {object: 3, amount: 20}, {object: 4, amount: 20}], machines:[{object: 4, amount: 1}]}},
                { id: 7, type: 'miner', baseobj:true, name: ' Worker Team with horses and carriage', description: 'Team of workers with horses and carriage.', picture: '/images/machines/min/miner-team.png', level: 6, itemsPerSecond: '22', outagePossibility: 35, outageFee: [{object: 0, amount: 500}], requirements: {objects: [{object: 0, amount: 500}, {object: 2, amount: 20}, {object: 5, amount: 10}, {object: 8, amount: 4}], machines:[{object: 5, amount: 2}]}},
                { id: 8, type: 'constructor', baseobj:false, name: 'Constructor Team with horses and carriage', description: 'Team of constructors with horses and carriage.', picture: '/images/machines/min/constructor-team.png', level: 7, itemsPerSecond: '22', outagePossibility: 35, outageFee: [{object: 0, amount: 500}], requirements: {objects: [{object: 0, amount: 500}, {object: 2, amount: 20}, {object: 5, amount: 10},{object: 8, amount: 4}], machines:[{object: 6, amount: 2}]}},
                { id: 9, type: 'miner', baseobj:true, name: 'Wind Mill', description: 'Wind Mills: Grind, Power, Flourish - Industrial Ingenuity!', picture: '/images/machines/min/windmill.png', level: 8, itemsPerSecond: '50', outagePossibility: 45, outageFee: [{object: 0, amount: 1500}], requirements: {objects: [{object: 0, amount: 1500}, {object: 2, amount: 200}, {object: 1, amount: 50}, {object: 12, amount: 10} ], machines:[{object: 7, amount: 2}]}},
                { id: 10, type: 'constructor', baseobj:false, name: 'Horse-powered mill', description: 'Equine Mechanism: Revolving, Thriving, Heritage - Pioneering Progress!.', picture: '/images/machines/min/horsemill.png', level: 9, itemsPerSecond: '50', outagePossibility: 45, outageFee: [{object: 0, amount: 1500}], requirements: {objects: [{object: 0, amount: 1500}, {object: 2, amount: 200}, {object: 1, amount: 50}, {object: 12, amount: 10} ], machines:[{object: 8, amount: 2}]}},
                { id: 11, type: 'miner', baseobj:true, name: 'Harvest Expedition Crew', description: 'A synchronized team with horses, wagons, and mills, efficiently gathering resources and sustenance.', picture: '/images/machines/min/harvest-expedition-crew.png', level: 10, itemsPerSecond: '150', outagePossibility: 100, outageFee: [{object: 0, amount: 3000},{object: 18, amount: 100}], requirements: {objects: [{object: 0, amount: 4000},{object: 18, amount: 125} ], machines:[{object: 7, amount: 2}, {object: 9, amount: 1}, {object: 3, amount: 4}]}},
                { id: 12, type: 'miner', baseobj:true, name: 'Master of Work', description: 'Scholarly master of resource acquisition strategies.', picture: '/images/machines/min/master-miner.png', level: 11, itemsPerSecond: '2', outagePossibility: 30, outageFee: [{object: 0, amount: 100},{object: 18, amount: 20}], requirements: {objects: [{object: 18, amount: 50},{object: 20, amount: 5}, {object: 15, amount: 10}, {object: 19, amount: 50}], machines:[{object: 1, amount: 1}]}},
                { id: 13, type: 'constructor', baseobj:false, name: 'Master of Construction', description: 'Adept scholar in advanced material refinement techniques.', picture: '/images/machines/min/master-constructor.png', level: 12, itemsPerSecond: '2', outagePossibility: 30, outageFee: [{object: 0, amount: 100}, {object: 18, amount: 20}], requirements:  {objects: [{object: 18, amount: 50},{object: 20, amount: 5}, {object: 15, amount: 10}, {object: 19, amount: 50}], machines:[{object: 2, amount: 1}]}},
                { id: 14, type: 'miner', baseobj:true, name: 'Vapor Harvest Engine', description: 'Efficiently extracts resources with potent steam power.', picture: '/images/machines/min/vapor-harvest-engine.png', level: 13, itemsPerSecond: '80', outagePossibility: 37, outageFee: [{object: 7, amount: 300},{object: 18, amount: 50}], requirements: {objects: [{object: 18, amount: 75}, {object: 21, amount: 1}, {object: 20, amount: 2}, {object: 15, amount: 10}, {object: 1, amount: 50}, {object: 2, amount: 50} ], machines:[{object: 12, amount: 2},{object: 4, amount: 2}]}},
                { id: 15, type: 'constructor', baseobj:false, name: 'Steam Forge Mechanism', description: 'Powers advanced processing for optimal material production.', picture: '/images/machines/min/steam-forge-mechanism.png', level: 14, itemsPerSecond: '80', outagePossibility: 37, outageFee: [{object: 7, amount: 300}, {object: 18, amount: 50}], requirements: {objects: [{object: 18, amount: 75}, {object: 21, amount: 1}, {object: 20, amount: 2}, {object: 15, amount: 10}, {object: 1, amount: 50}, {object: 2, amount: 50} ], machines:[{object: 13, amount: 2}, {object: 5, amount: 2}]}},
            ];
            commit('setinit',machines);
        }
    },
    mutations: {
        setinit(state, items) {
            state.all = items
        },
    },
    getters: {
        showMachines(state) {
            return state.all;
        },
        getMiner(state) {
            return state.all.filter((obj) =>  obj.type === 'miner' );
        },
        getConstructor(state) {
            return state.all.filter((obj) =>  obj.type === 'constructor' );
        },
        getObjectById(state) {
            return id => { state.all.find(obj => obj.id === id) }
        },
      getMaschineByLevel: (state) => (levelid) => {
        return state.all.filter((obj) => obj.level === levelid);
      },
    }
};