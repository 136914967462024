export const object = {
    namespaced: true,
    state: {
        all: [],

    },
    actions: {
        init({ commit},) {

          let objects = [
            { id: 0, name: 'Food', picture: '/images/objects/min/food.png', description: 'Basic food for workers', level: 1, baseobj: true, requirements: []},
            { id: 1, name: 'Stone', picture: '/images/objects/min/stone.png', description: 'Basic building material.', level: 1, baseobj: true, requirements: [{object: 0, amount: 1}] },
            { id: 2, name: 'Wood', picture: '/images/objects/min/wood.png', description: 'Versatile material obtained from trees.', level: 1, baseobj: true, requirements: [{object: 0, amount: 1}] },
            { id: 3, name: 'Stone Axes', picture: '/images/objects/min/stoneax.png', description: 'Basic tool for cutting and shaping.', level: 2, baseobj: false, requirements: [{ object: 1, amount: 1 }, { object: 2, amount: 1 }] },
            { id: 4, name: 'Rustic Hut', picture: '/images/objects/min/rustic-hut.png', description: 'A charming home blending raw wood and enduring stone.', level: 2, baseobj: false, requirements: [{ object: 1, amount: 5 }, { object: 2, amount: 5 }] },
            { id: 5, name: 'Ropes', picture: '/images/objects/min/rope.png', description: 'Twisted fibers used for binding.', level: 2, baseobj: false, requirements: [{ object: 1, amount: 1 }, { object: 2, amount: 3 }] },
            { id: 6, name: 'Iron Ore', picture: '/images/objects/min/iron-ore.png', description: 'Raw iron ore extracted from mines.', level: 3, baseobj: true, requirements: [{ object: 5, amount: 1 },{ object: 3, amount: 1 },{ object: 0, amount: 1 }] },
            { id: 7, name: 'Coal', picture: '/images/objects/min/coal.png', description: 'Carbon-rich fuel source.', level: 3, baseobj: true, requirements: [{ object: 5, amount: 1 },{ object: 3, amount: 1 },{ object: 0, amount: 1 }] },
            { id: 8, name: 'Wheel', picture: '/images/objects/min/wheel.png', description: 'Innovative Momentum in Motion.', level: 3, baseobj: false, requirements: [{ object: 1, amount: 1 }, { object: 2, amount: 3 }, { object: 3, amount: 1 }] },
            { id: 9, name: 'Iron', picture: '/images/objects/min/iron.png', description: 'Refined iron material for various applications.', level: 4, baseobj: false, requirements: [{ object: 6, amount: 1 },{ object: 7, amount: 1 },{ object: 3, amount: 1 }] },
            { id: 10, name: 'Copper Ore', picture: '/images/objects/min/copper-ore.png', description: 'Raw iron ore extracted from mines.', level: 4, baseobj: true, requirements: [{ object: 5, amount: 1 }, { object: 3, amount: 1 }, { object: 0, amount: 1 }] },
            { id: 11, name: 'Iron Plates', picture: '/images/objects/min/iron-plate.png', description: 'Flat sheets of processed iron.', level: 5, baseobj: false, requirements: [{ object: 9, amount: 1 },{ object: 7, amount: 2 },{ object: 3, amount: 1 }] },
            { id: 12, name: 'Iron Rods', picture: '/images/objects/min/iron-rod.png', description: 'Long rods of processed iron.', level: 5, baseobj: false, requirements: [{ object: 9, amount: 1 },{ object: 7, amount: 2 },{ object: 3, amount: 1 }] },
            { id: 13, name: 'Copper', picture: '/images/objects/min/copper.png', description: 'Refined copper material for various applications.', level: 6, baseobj: false, requirements: [{ object: 10, amount: 1 },{ object: 7, amount: 3 }] },
            { id: 14, name: 'Sand', picture: '/images/objects/min/sand.png', description: 'Fine granules for construction.', level: 6, baseobj: true, requirements: [{ object: 5, amount: 1 },{ object: 3, amount: 1 },{ object: 0, amount: 2 }] },
            { id: 15, name: 'Glass', picture: '/images/objects/min/glass.png', description: 'Transparent material for delicate structures.', level: 7, baseobj: false, requirements: [{ object: 14, amount: 2 }, { object: 7, amount: 2 }] },
            { id: 17, name: 'Gears', picture: '/images/objects/min/gear.png', description: 'Mechanical components for transmitting motion.', level: 7, baseobj: false, requirements: [{ object: 11, amount: 1 }, { object: 12, amount: 1 }, { object: 13, amount: 1 }] },
            { id: 18, name: 'Gold', picture: '/images/objects/min/gold.png', description: 'Currency of Elegance and Worth.', level: 7, baseobj: true, requirements: [{ object: 8, amount: 1 }, { object: 5, amount: 2 }, { object: 3, amount: 2 },{ object: 0, amount: 5 }] },
            { id: 19, name: 'Paper', picture: '/images/objects/min/paper.png', description: 'Versatile, writable, lightweight, essential material.', level: 8, baseobj: false, requirements: [{ object: 1, amount: 1 }, { object: 2, amount: 1 }] },
            { id: 20, name: 'Book', picture: '/images/objects/min/books.png', description: 'Knowledge in bound, portable form.', level: 9, baseobj: false, requirements: [{ object: 19, amount: 5 }] },
            { id: 21, name: 'Steam Engine', picture: '/images/objects/min/steam-engine.png', description: 'Efficient powerhouse converting steam energy.', level: 10, baseobj: false, requirements: [{ object: 11, amount: 1 }, { object: 12, amount: 1 }, { object: 13, amount: 1 }, { object: 17, amount: 1 }, { object: 20, amount: 1 }] },
            { id: 22, name: 'Drill Bit', picture: '/images/objects/min/drill-bit.png', description: 'Tool for drilling tasks.', level: 11, baseobj: false, requirements: [{ object: 12, amount: 1 }] },
            { id: 23, name: 'Crude Oil', picture: '/images/objects/min/crude-oil.png', description: 'Deep underground source of unrefined fossil fuel.', level: 12, baseobj: true, requirements: [{ object: 22, amount: 1 },{ object: 0, amount: 1 }] },
            { id: 24, name: 'Electricity', picture: '/images/objects/min/electricity.png', description: 'Vital force powering modern innovations.', level: 13, baseobj: true, requirements: [{ object: 7, amount: 1 },{ object: 0, amount: 1 }] },
            { id: 25, name: 'Gasoline', picture: '/images/objects/min/gasoline.png', description: 'Highly flammable fuel for engines.', level: 14, baseobj: false, requirements: [{ object: 23, amount: 1 },{ object: 7, amount: 1 }] },
            // to do requirements (!) and levels (?)

            // { id: 19, name: 'Copper Cables', picture: '/images/objects/min/copper-cable.png', description: 'Conductive cables made from copper.', level: 10, baseobj: false, requirements: [{ object: 13, amount: 1 }, { object: 7, amount: 3 }, { object: 3, amount: 1 }] },
            // { id: 20, name: 'Pistons', picture: '', description: 'Cylindrical devices for converting motion.', level: 10, baseobj: false, requirements: [{ object: 9, amount: 1 }, { object: 10, amount: 1 }, { object: 12, amount: 1 }] },
            // { id: 21, name: 'Wind Turbines', picture: '', description: 'Devices to harness wind energy for power.', level: 10, baseobj: false, requirements: [{ object: 13, amount: 15 }, { object: 14, amount: 15 }, { object: 11, amount: 10 }, { object: 12, amount: 10 }] },
            // { id: 22, name: 'Solar Panels', picture: '', description: 'Devices to convert sunlight into electricity.', level: 10, baseobj: false, requirements: [{ object: 13, amount: 15 }, { object: 14, amount: 15 }, { object: 11, amount: 10 }, { object: 12, amount: 10 }] },
            // { id: 23, name: 'Chemicals', picture: '', description: 'Complex substances created through chemical processes.', level: 10, baseobj: false, requirements: [{ object: 16, amount: 20 }, { object: 17, amount: 15 }, { object: 11, amount: 10 }, { object: 12, amount: 10 }] },
            // { id: 24, name: 'Catalysts', picture: '', description: 'Substances that facilitate chemical reactions.', level: 10, baseobj: false, requirements: [{ object: 16, amount: 20 }, { object: 17, amount: 15 }, { object: 11, amount: 10 }, { object: 12, amount: 10 }] },
            // { id: 25, name: 'Robots', picture: '', description: 'Automated machines capable of various tasks.', level: 10, baseobj: false, requirements: [{ object: 18, amount: 20 }, { object: 19, amount: 15 }, { object: 16, amount: 10 }, { object: 17, amount: 10 }] },
            // ... (repeat the pattern for the remaining objects)
          ];
            commit('setinit',objects);
        }
    },
    mutations: {
        setinit(state, items) {
            state.all = items
        },
    },
    getters: {
        showObjects(state) {
            return state.all;
        },
        getBaseObjects(state) {
            return state.all.filter((obj) =>  obj.baseobj === true );
        },
        getConstructedObjects(state) {
            return state.all.filter((obj) =>  obj.baseobj === false );
        },
        getObjectsByBaseobj(state) {
            return b => {
                state.all.filter((obj) =>  obj.baseobj === b )
            }
        },
        getObjectById: (state) => (id) => {
            return  state.all.find((obj) => obj.id === id);
        },
        getObjectsByLevel: (state) => (levelid) => {
            return state.all.filter((obj) => obj.level === levelid);
        },
    }
};