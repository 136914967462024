<template>
    <article class="tile is-child notification is-success box-padding shadow" style="background-image: url('/images/inventory.png');    background-position: center; background-repeat: no-repeat; background-size: cover;">
        <div :key="key" class="content">
            <p class="title text-shadow"><b-icon icon="warehouse"/> Inventory</p>
            <p class="subtitle">With even more content</p>
            <div class="content">
                <b-table :data="$store.getters['inventar/showInventarItems']" paginated pagination-simple per-page="6">
                    <template slot-scope="props">
                    <b-table-column field="object" label="Item"  sortable>
                      <b-tooltip :label="getName(props.row.object) ">
                        <figure class="image is-48x48 media-left" >
                          <img :src="getPicture(props.row.object)" class="is-rounded media-left">
                        </figure>
                      </b-tooltip>



                    </b-table-column>

                    <b-table-column field="count" label="Amount" width="40" sortable>
                        <animated-counter :value="props.row.count"/>
                    </b-table-column>
                    </template>
                </b-table>

                </div>
        </div>
    </article>
</template>
<script>
    import BTable from "buefy/src/components/table/Table";
    import BTableColumn from "buefy/src/components/table/TableColumn";
    import AnimatedCounter from "./AnimatedCounter";
    import BIcon from "buefy/src/components/icon/Icon";
    export default {
        name:'inventar',
        components:{BIcon, AnimatedCounter, BTableColumn, BTable},
        data() {
            return {
                key: 1,
                columns: [
                    {
                        field: 'object',
                        label: 'ID',
                        width: '40',
                        numeric: true
                    },
                    {
                        field: 'count',
                        label: 'amount',
                    } ]
            }
        },
        created() {
            this.key++;
        },
        methods: {
            getName(id) {
                return this.$store.getters['object/showObjects'].find(obj => obj.id === id).name
            },
          getPicture(id) {
            return this.$store.getters['object/showObjects'].find(obj => obj.id === id).picture
          }
        }
    }
</script>