import { inventarService } from './services';
import { ToastProgrammatic as Toast } from 'buefy'

export const inventar = {
    namespaced: true,
    state: {
        all: [],

    },
    actions: {
      checkAvailability({ state }, {object,count}) {
        return inventarService.checkAvailability(object,state.all,count)
      },
        addItem({ commit,state,rootGetters},data) {

            let allitems = state.all;
            let item = allitems.find(sobj => sobj.object === data.objid);

            let object = rootGetters['object/showObjects'].find(obj => obj.id === data.objid);

            if (inventarService.checkAvailability(object,allitems,data.count)) {
                let items = inventarService.reduceCount(object,allitems,data.count);
                allitems = items[0];
                let updatedItems = items[1];
                updatedItems.forEach(item => {
                    commit('update',item)
                });
                if(item) {
                    item.count = item.count+data.count;
                    commit('update',item)
                } else {
                    allitems.push({'object': data.objid, 'count': data.count})
                    commit('set',allitems);
                    Toast.open({
                        message: 'Nice job! Something new in your inventory!',
                        type: 'is-success'
                    })
                }
            }
        },
        updateInventar({commit},items) {
            commit('set',items);
        },
        updateItem({commit},item) {
            commit('update',item)
        },
        sync ({state}) {
            localStorage.setItem('inventar',JSON.stringify(state.all))
        },
        refresh ({commit}) {
            if(localStorage.getItem('inventar') !== null) commit('set',JSON.parse(localStorage.getItem('inventar')))
        }
    },
    mutations: {
        set(state, items) {
            state.all = items
        },
        update(state,item) {
            const olditem = state.all.find(i => i.object === item.object );
            Object.assign(olditem,item)
        }
    },
    getters: {

        showInventarItems(state) {
            return state.all;
        },
        getObjectAmount: (state) => (id) => {
            let obj = state.all.find(item => item.object === id);
            return (obj) ? obj.count : 0;
        }
    }
};