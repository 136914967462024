export const inventarService = {
    checkAvailability,
    reduceCount
};

function checkAvailability(objToBuild, inventarItems, count) {

    if (objToBuild.requirements.length > 0) {
        let available = true;
        objToBuild.requirements.forEach(item => {
            // check single Item in store inventarItems
            let avai = inventarItems.find(it => it.object === item.object);
            if(avai) {
                available = (available) ? avai.count >= item.amount * count : available;
            } else {
                available = false;
            }
        });
        return available;
    } else {
        return true;
    }
}

function reduceCount(objToBuild, inventarItems,count) {
    let newItems = []
    objToBuild.requirements.forEach(item => {
        // check single Item in store inventarItems
        let objToReduce = inventarItems.find(it => it.object === item.object);
        objToReduce.count = objToReduce.count - item.amount*count;
        newItems.push(objToReduce);
    });

    return [inventarItems,newItems];
}
